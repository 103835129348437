#contact-links{
    display: flex;
    flex-direction: column;
    /* width: fit-content; */
    /* align-items: flex-start; */
    /* width: fit-content; */
    /* max-width: 95%; */
}
h2{
    align-self: center;
}


.contact{
    /* width: max-content; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* align-self: center; */
    /* align-items: center; */
    /* justify-content: center; */
    padding: 1rem;
    text-overflow: clip;
    box-sizing: border-box;
    /* text-decoration: underline; */
}
.contact-title{
    /* text-decoration: underline;
    text-decoration-thickness: .1rem;
    text-underline-offset: .1rem; */
    font-size: 2rem;
    color: black;
    padding-bottom: .5rem;
    text-align: center;
}
.contact-info{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding-left: .5rem;
    /* font-size: 2rem; */
    text-align: center;
    word-break: break-all;
    display: inline;
    text-decoration: none;
    color: rgb(85, 85, 85);
    font-size: 1.5rem;
}
.contact a{
    text-decoration: underline;
    text-decoration-thickness: .02rem;
    text-underline-offset: .1rem;
}
.contact a:hover{
    color : rgb(70, 181, 233);
    /* text-decoration: underline; */
    cursor: pointer;
}