.projects{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: black 1px solid; */
    padding: 2rem 0 2rem 0;
    box-sizing: border-box;
    margin-top: 1rem;
    width: 95%;
    background: none;
}

.project a{
    color: black;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: rgba(0, 0, 0, 0.61); */
}
.project a:hover{
    color: rgb(70, 181, 233);
    cursor: pointer;
}
.project a:active{
    color: orange
}
.project:nth-of-type(1){
    background: none;
}

.project{
    border-top: black 1px solid;
    /* border: black 1px solid; */
}

/* .project:nth-of-type(2n){
    background: none;
  }
.project:nth-of-type(1){
background-color: none;
} */

.project aside{
    padding-bottom: 1rem;
    /* font-size: 1rem; */
}

.project h3{
    /* padding-bottom: 1rem; */
    font-size: 3rem;
    /* font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    
    text-align: center;
    word-wrap: break-word;
}

.project p{
    margin-bottom: .5rem;
    width: 85%;
}

.project > img{
    width: 90%;
}

@media (max-width: 780px) {
    .project h3{
        font-size: 2.4rem;
    }
}
@media (max-width: 350px) {
    .project h3{
        font-size: 2rem;
    }
}

#bbox-disclaimer{
    font-weight: 700;
    font-size: 1.25rem;
}