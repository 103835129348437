#skills{
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* max-width: 800px; */
}
#skills p{
    text-align: left;
}
#skills-container{
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: space-between;
    list-style:circle;
    flex-wrap: wrap;
    /* max-height: 200px; */
}
.skill{
    font-size: 2rem;
    margin: 1rem;
    /* width:max-content; */
    color: blue;
    font-family: 'Courier New', Courier, monospace;
    padding: 0;
}
.skill:nth-of-type(2n){
    color: rgb(255, 0, 76);
}
.skill:nth-of-type(3n){
    color:rgb(163, 3, 212);
}

@media (max-width: 700px) {
    .skill{
        font-size: 1.5rem;
        margin:.75rem;
    }
}