#intro{
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 0;
    position: relative;
    /* justify-content: center; */
    width: 100%;
    min-height: 500px;
    height: 100vh;
    justify-content: center;
    background-color: transparent;
}

#profile-pic{
    box-sizing: border-box;
    height: 18vw;
    width: 18vw;
    min-width: 150px;
    min-height: 150px;
    border-radius: 50%;
    border: black 1px solid;
    display: block;
    object-fit:cover;
    z-index: 5;
    margin-bottom: 2rem;
}

#intro-background{
    position: fixed;
    z-index: -1;
    /* top: 0; */
    /* left: 0; */
    object-fit: cover;
    object-position:right;
    box-sizing: border-box;
    transition: all 1s;
}
#intro-background.hide{
    opacity: 0;
}
#intro-background.show{
    width: 100%;
    height: 100vh;
    min-height: 500px;
    opacity: 1;
    bottom: 0;
    border-radius: 0%;
}

.int-back-filt{
    height: 100%;
    min-height: 500px;
    background-color: rgba(0, 0, 0, 0.5);
}

#profile-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* flex-wrap: wrap; */
    position: relative;
    z-index: 5;
    color: rgb(248, 244, 1);
    font-family: 'Zen Loop', cursive;
    width: 65vw;
    /* height: 60% */
    /* padding-top: 10vh; */
    /* min-height: 50%; */
    box-sizing: border-box;
    /* margin: 2rem; */
    /* background-color: rgba(0, 0, 0, 0.548);
    -webkit-box-shadow: 0px 0px 15px 5px #000000; 
box-shadow: 0px 0px 15px 5px #000000; */
transition: all 1s;
transition-delay: 1s;
}
#profile-wrapper.hide{
    bottom: 10%;
    opacity: 0;
}
#profile-wrapper.show{
    bottom: 0%;
    opacity: 1;
}
#pic-link{
    display: flex;
}
#links{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
}
.intro-p{
    font-size: 2.5rem;
    text-align: center;
}
#my-name{
    padding: .5rem;
    font-size: 5rem;
    display: block;
    text-align: center;
}

/* @media (max-width: 700px) {
    #intro{
        justify-content: center;
    }
} */