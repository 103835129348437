/* embed{
    width: 100%;
    min-height: 500px;
} */
iframe{
    max-width: 800px;
    width: 100%;
    height: 110vw;
    max-height: 1100px;
}

#resume-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 3rem;
    box-sizing: border-box;
    /* width: 70%; */
    max-width: 95vw;
}

#resume-link{
    color:black;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
}
#resume-link h2{
    padding-bottom: 0;
}
#resume-link:hover{
    color: rgb(70, 181, 233);
    cursor: pointer;
}
#resume-link aside{
    padding-bottom: 1rem;
}