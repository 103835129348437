body{
  background: rgb(78, 142, 167);
  /* background-color: ; */
  height: 100%;
  /* padding: 1rem; */
  z-index: -2;
  position:relative;
  /* font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  overflow-x: hidden;
  /* overflow-y: hidden; */
}
section:nth-of-type(2n){
  background: rgb(154, 238, 253);
}
section:nth-of-type(2n+1){
  background-color: rgb(172, 255, 255);
}
button{
  position: relative;
  z-index: 5;
}

.pic-filter{
  position:fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.322);
  z-index: 0;
}
h2{
  display: block;
  text-align: center;
  /* padding-top: 3rem; */
  padding-bottom: 1rem;
  text-decoration: underline;
  text-decoration-thickness: .1rem;
  text-underline-offset: 2px;
  font-size: 4rem;
  /* color:rgb(173, 0, 173); */
  font-family: 'Zen Loop', cursive;

}
h3{
  /* text-decoration: underline; */
  font-size: 2rem;
}
p{
  font-size: 1.4rem;
  /* letter-spacing: .05em; */
  line-height: 2.5rem;

}


#app{
  /* padding: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}
#app > * + * {
  margin-top: 1.5rem;
  
}

.card{
  box-sizing: border-box;
  width: 90%;
  padding: 2rem;
  border-radius: 10px;

}

@media (max-width: 700px) {
  .card{
    width: 95%;
  }

  p{
    font-size: 1rem;
  }
}
