.abt-pic{
    width: 17vw;
    height: 17vw;
    min-width: 200px;
    min-height: 200px;
    object-fit: cover;
    object-position: center;
    /* display: none; */
}
#about{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}
.abt-pic-txt-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-bottom: 1rem; */
    /* border: black 1px solid; */
    width: 80vw;
    /* height: 400px; */
    padding: 1rem;
    box-sizing: border-box;
}

.abt-txt{
    width: 50vw;
    /* height: 100%; */
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 1rem;
}
@media (max-width: 760px) {
    .abt-pic-txt-container{
        flex-direction: column;
        justify-content: center;
    }
    .abt-pic{
        display: none;
    } 
    .abt-txt{
        width: 80vw;
        /* font-size: 1rem; */
    }
}